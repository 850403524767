import { useMemo } from 'react'
import useWindowSize from './useWindowSize'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

type BreakpointData = {
  breakpoint: Breakpoint
  xsDown: boolean
  smDown: boolean
  mdDown: boolean
  lgDown: boolean
  xlDown: boolean
  '2xlDown': boolean
  xsUp: boolean
  smUp: boolean
  mdUp: boolean
  lgUp: boolean
  xlUp: boolean
  '2xlUp': boolean
}

export function useBreakpoint(): BreakpointData {
  const { width } = useWindowSize()

  const { breakpoint, downs, ups } = useMemo(() => {
    const bp: Breakpoint =
      width >= 1536
        ? '2xl'
        : width >= 1280
          ? 'xl'
          : width >= 1024
            ? 'lg'
            : width >= 768
              ? 'md'
              : width >= 640
                ? 'sm'
                : 'xs'

    const computedDowns = {
      xsDown: width < 640,
      smDown: width < 768,
      mdDown: width < 1024,
      lgDown: width < 1280,
      xlDown: width < 1536,
      '2xlDown': false,
    }

    const computedUps = {
      xsUp: width >= 640,
      smUp: width >= 768,
      mdUp: width >= 1024,
      lgUp: width >= 1280,
      xlUp: width >= 1536,
      '2xlUp': true,
    }

    return { breakpoint: bp, downs: computedDowns, ups: computedUps }
  }, [width])

  return {
    breakpoint,
    xsDown: downs.xsDown ?? false,
    smDown: downs.smDown ?? false,
    mdDown: downs.mdDown ?? false,
    lgDown: downs.lgDown ?? false,
    xlDown: downs.xlDown ?? false,
    '2xlDown': downs['2xlDown'] ?? false,
    xsUp: ups.xsUp ?? false,
    smUp: ups.smUp ?? false,
    mdUp: ups.mdUp ?? false,
    lgUp: ups.lgUp ?? false,
    xlUp: ups.xlUp ?? false,
    '2xlUp': ups['2xlUp'] ?? false,
  }
}
